import { Company, RCSSettings } from 'types/general';
import { getCookies, setCookies } from './cookiesHelper';
import dayjs from 'dayjs';
import { IFilterItems, User } from 'types/user';
import * as htmlToImage from 'html-to-image';
import { jsPDF } from 'jspdf';
import { IRecentSearch, SearchTab } from '../components/layout/Search';

export function encodeBase64(text: string): string {
  if (!text) return '';
  const buff = Buffer.from(text);
  return buff.toString('base64');
}

export function decodeBase64(text: string): string {
  const base64regex =
    /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
  if (!text || !base64regex.test(text)) return '';
  const buff = Buffer.from(text, 'base64');
  const res = buff.toString('utf-8');
  return res;
}

export function parseJSON(text: string): any {
  try {
    return JSON.parse(text);
  } catch {
    return null;
  }
}

export function validateEmail(email: string) {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export const restrictInput = (inputText: string, regex: RegExp): string =>
  inputText.replace(regex, '');

export const getCompanies = () => {
  try {
    const companies = getCookies('companies');
    return JSON.parse(companies || '') as Company[] | undefined;
  } catch {
    return undefined;
  }
};

export const getCompany = (): Company | undefined => {
  const slug = getCookies('slug');

  return getCompanies()?.find((item: any) => item.slug === slug);
};

export const getCompanyId = () => {
  return getCompany()?.id;
};

export const getCompanyLicenseKey = () => {
  return getCompany()?.license_key;
};

export const getCompanyName = () => {
  return getCompany()?.name;
};

export const getCompanyRole = () => {
  return getCompany()?.role;
};

export const getCompanySlug = () => {
  return getCompany()?.slug;
};

export const getCompanyPermissions = () => {
  return getCompanyRole()?.permissions ?? [];
};

export const getSubdomain = () => {
  return window.location.hostname.split('.')[0];
};

export const getSlug = () => {
  return (
    process.env.NEXT_PUBLIC_COMPANY_SLUG ||
    window.location.hostname.split('.')[0]
  );
};

export const urlWithSlug = (href: string) => {
  return `/${getCookies('slug')}${href}`;
};

export const getUserInitial = (name: string | undefined) => {
  return name
    ?.split(' ')
    ?.slice(0, 3)
    ?.map((data) => data.charAt?.(0)?.toUpperCase?.())
    ?.join('');
};

export const getOrgSettingsFromCookies = (): RCSSettings | undefined => {
  const orgSettings = getCookies('org_settings');
  return orgSettings ? (JSON.parse(orgSettings) as RCSSettings) : undefined;
};

export const isUsingUsername = (): boolean => {
  const orgSettings = getCookies('org_settings') as string;
  if (orgSettings)
    return Boolean((JSON.parse(orgSettings) as RCSSettings).is_username_login);
  return false;
};

export const getLoginType = (): string => {
  const orgSettings = getCookies('org_settings') as string;
  if (orgSettings) {
    const method = JSON.parse(orgSettings)['login_methods']?.find(
      (item: { code: string; is_default: boolean }) => item.is_default
    );
    if (method && method !== -1) return method.code;
  }
  return 'standard';
};

export const dateParser = (date?: string, parser?: string) => {
  if (date === '') {
    return parser ? dayjs().format(parser) : dayjs().format('DD MMM YYYY');
  }
  return parser
    ? dayjs(date).format(parser)
    : dayjs(date).format('DD MMM YYYY');
};

export const bytesToMegaBytes = (bytes: number): string => {
  const mb = bytes / 1048576;
  const fixedMb = mb.toFixed(2);
  return `${fixedMb} MB`;
};

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const getUser = () => {
  return JSON.parse(getCookies('user') || '{}') as User;
};

export function camelToTitleCase(text: string) {
  return text
    .replace(/([A-Z])/g, ' $1')
    .replace(/^./, (str) => str.toUpperCase());
}

// image base64-encoded data URL to base64-encoded image
export function toImageFile(dataURL: string, filename: string) {
  const arr = dataURL.split(',');
  const mime = arr[0].match(/:(.*?);/)![1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

export function expandElement() {
  document.querySelectorAll('.contentScrollable').forEach((el: any) => {
    // remove max height and height
    el.style.maxHeight = 'none';
    el.style.height = 'auto';
    el.style.paddingBottom = '10px';
  });
}

export function revertElement() {
  document.querySelectorAll('.contentScrollable').forEach((el: any) => {
    // revert max height and height
    el.style.maxHeight = '';
    el.style.height = '';
    el.style.paddingBottom = '';
    // el.style.width = '';
  });
}

export function elementToImage(id: string) {
  const el = document.getElementById(id) as HTMLElement;
  return htmlToImage.toPng(el);
}

export async function downloadElementAsImage(id: string, name?: string) {
  expandElement();
  const url = await elementToImage(id);
  const date = dayjs().format('DDMMYYYY');
  const a = document.createElement('a');
  a.href = url;
  a.download = name ?? `${date}-${camelToTitleCase(id)}.png`;
  a.click();
  revertElement();
}

export async function elementToPdf(id: string) {
  expandElement();
  const url = await elementToImage(id);
  revertElement();

  const element = document.getElementById(id) as HTMLElement;
  const imageWidth = 180;
  const imageHeight = (element.offsetHeight * imageWidth) / element.offsetWidth;
  const x = 15;

  const doc = new jsPDF({
    orientation: 'portrait',
    unit: 'mm',
    format: 'a4',
  });

  doc.addImage(url, 'PNG', x, 20, imageWidth, imageHeight);

  return doc;
}

export async function downloadElementAsPDF(id: string, name?: string) {
  const doc = await elementToPdf(id);

  const date = dayjs().format('DDMMYYYY');
  doc.save(name ?? `${date}-${camelToTitleCase(id)}.pdf`);
}

export function urlTo(path: string) {
  return window.location.origin + path;
}

export function urlToSlug(path: string) {
  return urlTo(`/${getCompanySlug()}/${path}`);
}

export function calculatePercentage(numerator?: number, denominator?: number) {
  if (denominator == null || numerator == null || denominator === 0) {
    return 0;
  }
  return (numerator / denominator) * 100;
}

export const parsedParams = (
  data: Array<string>,
  type: string
): {
  download_scopes: string;
  download_based: string;
  filename: string;
} => {
  const keyMap = new Map([
    ['cardComplianceRate', 'compliance-rate'],
    ['complianceRateRegulationBased', 'compliance-rate'],
    [
      'obligationComplyByGroup',
      type === 'regulation'
        ? 'obligation-comply-by-regulation'
        : 'obligation-comply-by-user-group',
    ],
    [
      'In Progress',
      `obligation-in-progress-by-${type === 'regulation' ? 'regulation' : 'user-group'}-in-progress`,
    ],
    [
      'Assigned',
      `obligation-in-progress-by-${type === 'regulation' ? 'regulation' : 'user-group'}-assigned`,
    ],
    [
      'Ready to Review',
      `obligation-in-progress-by-${type === 'regulation' ? 'regulation' : 'user-group'}-ready-to-review`,
    ],
    ['obligationComplyByType', 'obligation-type'],
    ['complianceRateByUserGroup', 'compliance-rate-by-user-group'],
    ['complianceRateByDate', 'compliance-rate-by-date'],
    ['Monthly', 'compliance-rate-by-date-monthly'],
    ['Weekly', 'compliance-rate-by-date-weekly'],
    ['Daily', 'compliance-rate-by-date-daily'],
    ['complianceMonitor', 'latest-compliance-monitor'],
    ['complianceFiles', 'latest-compliance-files'],
    ['Raw Data Compliance Monitor', 'raw-data-compliance-monitor'],
    [
      'obligationComplyByGroupOrRegulation',
      'obligation-in-progress-by-regulation',
    ],
    [
      'regulationHierarchy',
      type === 'regulatory-framework'
        ? 'regulation-by-status'
        : 'regulation-hierarchy',
    ],
    ['complianceRateByRegulation', 'compliance-rate-by-regulation'],
    ['regulationFrameworkInformation', 'regulatory-framework-information'],
    ['regulatoryFrameworkChart', 'regulatory-framework-chart'],
    [
      'Obligation by Sanction',
      'regulatory-framework-chart-obligation-by-sanction',
    ],
    ['Obligation by Type', 'regulatory-framework-chart-obligation-by-type'],
    ['sectorList', 'sector-list'],
    ['regulationFrameworkObligationStatus', 'obligation-by-status'],
    ['obligationUpdates', 'latest-obligation-updates'],
    ['regulationUpdates', 'latest-regulation-updates'],
  ]);

  const res = data.map((item) => keyMap.get(item) || item);
  const capitalizeWords = (type: string) =>
    type
      .split('-')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');

  const checkRequiredKeys = (requiredKeys: Array<string>) =>
    requiredKeys.some((key) => data.includes(key));
  if (checkRequiredKeys(['In Progress', 'Assigned', 'Ready to Review'])) {
    res.push(
      `obligation-in-progress-by-${type === 'regulation' ? 'regulation' : 'user-group'}`
    );
  }
  if (checkRequiredKeys(['Monthly', 'Weekly', 'Daily'])) {
    res.push('compliance-rate-by-date');
  }
  if (checkRequiredKeys(['Obligation by Sanction', 'Obligation by Type'])) {
    res.push('regulatory-framework-chart');
  }
  if (checkRequiredKeys(['Raw Data Compliance Monitor'])) {
    res.push('latest-compliance-monitor');
  }

  const typeMap: Record<string, string> = {
    obligation: 'obligation-based',
    regulation: 'regulation-based',
    'regulatory-framework': 'regulatory-framework-based',
  };
  const downloadBase =
    type === 'regulatory-framework' ? 'regulatory-framework' : typeMap[type];
  const dateNow = dayjs().format('DDMMYYYY');
  // const formattedScopes = res.map((scope) => capitalizeWords(scope)).join('-');
  // const formattedBase = capitalizeWords(typeMap[type]);
  const filename = `${dateNow}-${capitalizeWords(downloadBase)}-Dashboard`;

  return {
    download_scopes: Array.from(new Set(res)).join(','),
    download_based: downloadBase,
    filename,
  };
};

export const parsedTabValue = (tab: string): string => {
  let tabType: string = '';
  switch (tab) {
    case 'day':
      tabType = 'Daily';
      break;
    case 'month':
      tabType = 'Monthly';
      break;
    case 'week':
      tabType = 'Weekly';
      break;
  }
  return tabType;
};

export function getRecentSearchFromStorage(): IRecentSearch[] {
  const recentSearch = getCookies('recent_search');
  return recentSearch ? JSON.parse(recentSearch) : [];
}

export function addRecentSearch(query: string, tab: SearchTab) {
  const recentSearch = getRecentSearchFromStorage();
  const existingIndex = recentSearch.findIndex(
    (item) => item.label === query && item.tab === tab
  );

  if (existingIndex !== -1) {
    // If the search already exists, move it to the beginning of the array
    const existingItem = recentSearch.splice(existingIndex, 1)[0];
    recentSearch.unshift(existingItem);
  } else {
    // If the search is new, add it to the beginning of the array
    recentSearch.unshift({ label: query, value: query, tab: tab });
  }

  setCookies({ key: 'recent_search', value: JSON.stringify(recentSearch) });
}

export const getValueFromFilterItems = (
  filterItems: Array<IFilterItems>,
  filterName: string
): string | undefined => {
  return filterItems.find((item) => item.name === filterName)?.value;
};

export const onlyNumbersAllowed = /[^0-9]/g;

const getUserPermissions = () => {
  const role = getCompanyRole();
  return role?.permissions?.map((p) => p.code) || [];
};

const hasPermissions = (
  permissions: string[],
  requiredPermissions?: string[]
) => {
  if (!requiredPermissions) return true;
  return requiredPermissions.some((p) => permissions.includes(p));
};

export const getUserEntryPoint = (isOnLogin: boolean) => {
  const userPermissions = getUserPermissions();

  const permissionsMapping = [
    { permission: 'view-compliance-dashboard', url: '/home' },
    { permission: 'view-regframe-dashboard', url: '/home?tab=regframe' },
    { permission: 'view-all-compliance', url: '/compliance-monitor' },
    { permission: 'view-obligation', url: '/sector' },
    { permission: 'view-all-activity', url: '/activity' },
    { permission: 'view-billing', url: '/company-management/billing-info' },
    { permission: 'edit-theme-setting', url: '/rcs-setting/theme' },
    { permission: 'upload-initial-content', url: '/rcs-setting/data' },
    { permission: 'view-user', url: '/company-management/user' },
    { permission: 'view-group', url: '/company-management/user-group' },
    { permission: 'view-role', url: '/company-management/privilege' },
    {
      permission: 'view-role-compliance',
      url: '/company-management/role-compliance',
    },
  ];

  for (const { permission, url } of permissionsMapping) {
    if (hasPermissions(userPermissions, [permission])) {
      return isOnLogin ? url : urlWithSlug(url);
    }
  }

  return urlWithSlug('/personal-setting/account');
};
